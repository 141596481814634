<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>පැකේජ ඉල්ලීම්</h4>
    </header>

    <!-- Member search bar -->
    <v-row>
      <v-col class="pt-0" cols="8" md="4">
        <v-text-field
          @keypress.enter="searchMember"
          class="mb-2 custom-shadow"
          clearable
          flat
          hide-details
          label="සාමාජික අංකය ඇතුලත් කරන්න"
          solo
          v-model.lazy="searchText"
        ></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="4" md="2">
        <v-btn
          :loading="loadingSearchBtn"
          @click="searchMember"
          class="custom-shadow"
          color="secondary"
          style="height: 48px;"
        >සොයන්න</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="member">
      <v-col cols="12" lg="7" md="6">
        <v-sheet class="custom-shadow px-2">
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">සාමාජික අංකය: {{member.id}}</v-col>
              <v-col cols="12" lg="6">දුරකතන අංකය: {{member.mobileNumber}}</v-col>
            </v-row>
            <v-divider class="mt-0 mb-2"></v-divider>
            <v-row>
              <v-col cols="12" lg="6" md="12" sm="6">
                <span class="body-2 font-weight-bold text-secondary">මාර්ගගත පැකේජයන්</span>
                <v-select
                  :items="packages"
                  class="my-4"
                  dense
                  flat
                  hide-details
                  item-text="name"
                  item-value="id"
                  label="පැක‌ේජය"
                  outlined
                  v-model="member.currentPackageId"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="6" v-if="member.currentPackageId == 1">
                <span class="body-2 font-weight-bold text-secondary">මාර්ගගත නොවන පැකේජයන්</span>
                <v-select
                  :items="manualPackages"
                  class="my-4"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="price"
                  item-value="id"
                  label="පැක‌ේජය"
                  outlined
                  v-model="member.manualPackageId"
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              :loading="loadingActivateBtn"
              @click="activate"
              block
              color="accent"
              depressed
            >සක්‍රිය කරන්න</v-btn>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="12" lg="5" md="6">
        <v-sheet class="custom-shadow px-2">
          <v-container>
            <h6 class="mt-3">වත්මන් පැකේජයේ විස්තර</h6>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col cols="8">
                <span class="body-2">ආරම්භ කල දිනය</span>
              </v-col>
              <v-col cols="4">
                <span class="body-2">{{member.packageStartedDate.split(" ", 1)[0]}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <span class="body-2">කල් ඉකුත්වන දිනය</span>
              </v-col>
              <v-col cols="4">
                <span
                  class="body-2"
                  v-if="member.packageEndDate"
                >{{member.packageEndDate.split(" ", 1)[0]}}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col cols="8">
                <span class="body-2">ඉතිරි ලිපි වාරික</span>
              </v-col>
              <v-col cols="4">
                <span class="body-2">{{member.remainingRounds}}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },

  data: () => ({
    packages: [],
    manualPackages: [],
    searchText: '',
    loadingSearchBtn: false,
    loadingActivateBtn: false,
    member: '',
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    this.fetchPackages();
    await this.fetchManualPackages();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch packages
    fetchPackages: async function () {
      try {
        const url = 'packages';
        const response = await ApiService.get(url);
        this.packages = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    // Fetch manual packages
    fetchManualPackages: async function () {
      try {
        const url = 'manual-packages';
        const response = await ApiService.get(url);
        this.manualPackages = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    // Search members by id
    searchMember: async function () {
      this.loadingSearchBtn = true;
      try {
        const url = "customers";
        const params = {
          params: {
            id: this.searchText, approved: 1, nicVerified: 1, mobileNumberVerified: 1
          }
        };
        const response = await ApiService.get(url, params);
        this.member = response.data.data[0];
      } catch (error) {
        console.log(error);
      }
      this.loadingSearchBtn = false;
    },

    // Activate requested package
    activate: async function () {
      if (await this.$refs.confirm.open('සක්‍රිය කරන්න', 'ඔබට විශ්වාසද ?', { color: 'accent', persistent: true })) {
        this.loadingActivateBtn = true;
        try {
          const url = 'customers/activate-package';
          let data = { customer: this.member.id, package: this.member.currentPackageId };
          if (this.member.currentPackageId == 1) {
            data.manualPackage = this.member.manualPackageId;
          }
          const response = await ApiService.put(url, data);
          this.member.packageStartedDate = response.data.packageStartedDate;
          this.member.packageEndDate = response.data.packageEndDate;
          this.member.remainingRounds = response.data.remainingRounds;
          this.showAlert({ message: 'නව පැකේජය සක්‍රිය කරන ලදි', type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loadingActivateBtn = false;
      }
    }
  }
}
</script>